import logo from "../assets/logo1.svg";
import scissors from "../assets/Scissors.svg"
import button from "../assets/Button.svg"
import logo2 from "../assets/secondaryLogo.svg"
import mensTop from "../assets/mensTop.svg"
import womensTop from "../assets/womensTop.svg"
import arrow from "../assets/arrow.svg"
import prop1 from "../assets/prop1.svg"
import cutting from "../assets/cutting.svg"
import styling from "../assets/Styling.svg"
import Services from "../assets/Services.svg"
import chair from "../assets/My project (4) 1.svg"
import lineButton from "../assets/lineButton.svg"
import men1 from "../assets/men1.svg"
import men2 from "../assets/men2.svg"
import men3 from "../assets/men3.svg"
import men4 from "../assets/men4.svg"
import men5 from "../assets/men5.svg"
import men6 from "../assets/men6.svg"
import line2 from "../assets/Line 2.svg"
import women1 from "../assets/women1.svg"
import women2 from "../assets/women2.svg"
import women3 from "../assets/women3.svg"
import women4 from "../assets/women4.svg"
import women5 from "../assets/women5.svg"
import women6 from "../assets/women6.svg"
import women7 from "../assets/women7.svg"
import loginButton from "../assets/Group 23.svg"
import consultations from "../assets/Consultations.svg"
import treatments from "../assets/treatments.svg"
import prop2 from "../assets/prop2.svg"

const images = {loginButton,logo,scissors,button,logo2,mensTop,womensTop,arrow,prop1,cutting,styling,Services,chair,lineButton,men1
    ,men2,men3,men4,men5,men6,line2,women1,women2,women3,women4,women5,women6,women7, consultations,treatments, prop2}

export default images;